import { useState } from "react";
import Logo from "../images/raven.svg";
import SearchBar from "./SearchBar";
export default function Header(props) {
  const [query, setQuery] = useState({
    label: "",
    value: "",
  });
  return (
    <div className="px-4 sm:px-6">
      <div className="flex border-b-2 border-gray-300">
        <div className="flex w-full align-center">
          <div className="px-2 md:w-1/3">
            <a href="/">
              <img className="block h-16 w-auto" src={Logo} alt="Crow" />
            </a>
          </div>
          <div className="w-full md:w-1/3 ">
            <SearchBar
              defaultValue={{
                label: props.searchQuery,
                value: props.searchQuery,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
