import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="flex w-full h-10 items-center justify-center">
      <div className="flex w-full pt-4 justify-around md:w-2/3 md:justify-around items-center ">
        <div className="md:w-2/5 text-center hover:underline">
          <Link to="/about">About</Link>
        </div>
        <div className="md:w-2/5 text-center hover:underline">
          <Link to="/terms">Terms</Link>
        </div>
        <div className="md:w-2/5 text-center hover:underline">
          <Link to="/privacy">Privacy</Link>
        </div>
        <div className="md:w-2/5 text-center hover:underline">
          <a href="mailto:aksanoble@gmail.com">Contact</a>
        </div>
      </div>
    </div>
  );
}
