import AsyncSelect from "react-select/async";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const customStyles = {
  indicatorsContainer: (provided, state) => {
    return { display: "none" };
  },
};

export default function SearchBar(props) {
  const location = useLocation();
  const [query, setQuery] = useState({
    label: "",
    value: "",
  });
  useEffect(() => {
    console.log("route changed", location);
  }, [location]);
  const navigate = useNavigate();
  const onSearch = (option) => {
    // const query = `/search?query=${option.value
    //   .split(" ")
    //   .map((w) => encodeURIComponent(w))
    //   .join("+")}`;
    navigate(option.value.url);
  };

  const fetchResults = async (query) => {
    const response = await fetch(`${process.env.REACT_APP_SEARCH_URL}/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query }),
    });
    const data = await response.json();
    return data;
  };

  const loadOptions = async (inputValue) => {
    const options = await fetchResults(inputValue);
    if (options.length) {
      console.log(options[0], "options");
      return options.map((o) => ({
        label: `${o.n.properties.__name} | ${o.n.labels.join(" ")}`,
        value: {
          name: o.n.properties.__name,
          url: `/e/${o.n.identity.low}`,
        },
      }));
    }
    return [{ label: inputValue, value: inputValue }];
  };

  return (
    <AsyncSelect
      styles={customStyles}
      className="w-full"
      defaultValue={props.defaultValue}
      onChange={onSearch}
      loadOptions={loadOptions}
      value={query}
      noOptionsMessage={() => null}
    />
  );
}
