export default function Entity(props) {
  const { properties, labels, relations } = props.entity;
  const Properties = Object.keys(properties)
    .filter((key) => !key.includes("__"))
    .map((key, index) => (
      <div key={index} className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">{key}</dt>
        <dd className="mt-1 text-sm text-gray-900">{properties[key]}</dd>
      </div>
    ));

  const RelationsList = (relations) => {
    return relations.map((rel, index) => (
      <div key={index}>
        <a
          className="text-blue-800 font-bold hover:underline"
          href={`/e/${rel.identity.low}`}
        >
          {rel.properties.__name}
        </a>
      </div>
    ));
  };
  const Relations = Object.keys(relations)
    .filter((key) => !key.includes("__"))
    .map((key, index) => (
      <div key={index} className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">{key}</dt>
        <dd className="mt-1 max-h-[10rem] overflow-y-scroll text-sm text-gray-900">
          {RelationsList(relations[key])}
        </dd>
      </div>
    ));
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {properties.__name}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Type: {labels[0]}
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {Properties}
          {Relations}
        </dl>
      </div>
    </div>
  );
}
