import { useEffect, useState } from "react";
import Header from "./Header";
import Entity from "./Entity";
import { useLocation, useMatch } from "react-router-dom";
import Footer from "./Footer";
import { isEmpty } from "lodash";

const fetchEntity = async (entityId, setEntity, setIsLoading) => {
  setIsLoading(true);
  const response = await fetch(
    `${process.env.REACT_APP_SEARCH_URL}/e/${entityId}`
  );
  const responseStatus = response.status;
  if (responseStatus === 404) {
    setEntity({});
    setIsLoading(false);
  } else {
    let data = await response.json();
    data = data.reduce(
      (acc, curr) => {
        if (!acc.properties) {
          acc = {
            ...acc,
            ...curr.n,
          };
        }
        if (curr.r) {
          if (!acc.relations[curr.r.type]) {
            acc.relations[curr.r.type] = [];
          }
          acc.relations[curr.r.type].push(curr.b);
        }
        return acc;
      },
      { relations: {} }
    );
    console.log("entity", data);
    setEntity(data);
    setIsLoading(false);
  }
};
export default function EntityRoute(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [entity, setEntity] = useState({});

  const location = useLocation();
  const matchPath = useMatch("/e/:id");

  useEffect(() => {
    console.log(location, "location");
    const entityId = matchPath.params.id;
    console.log(matchPath.params.id, "matchPath");
    fetchEntity(entityId, setEntity, setIsLoading);
  }, [location]);

  console.log(entity, "Entity");
  return (
    <div className="min-h-[100vh] p-5">
      <Header searchQuery={""} />
      <div className="flex py-10 min-h-[80vh] justify-center">
        {isLoading ? (
          <div className="md:w-2/4">
            <div>Loading...</div>
          </div>
        ) : !isLoading && !entity.properties ? (
          <div className="text-lg max-w-prose mx-auto mb-6">
            <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Not found!
            </h1>
            <p className="text-xl text-center text-gray-500 leading-8">
              <a href="/">Go to the home page.</a>
            </p>
          </div>
        ) : (
          <div className="w-full md:w-1/2">
            <Entity entity={entity} />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
