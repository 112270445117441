import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Results from "./components/Results";
import EntityRoute from "./components/EntityPage";
import About from "./components/About";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import { ErrorBoundaryContext } from "react-use-error-boundary";
import ErrorBoundary from "./components/ErrorBoundary";
import NotFound from "./components/NotFound";
import mixpanel from "mixpanel-browser";
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init("096d76c77c71689ff504678a9f0973cf");
mixpanel.track("Visit");
function App() {
  return (
    <ErrorBoundaryContext>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/terms" element={<Terms />}></Route>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route path="/search" element={<Results />} />
          <Route
            path="/e/*"
            element={
              <ErrorBoundary>
                <EntityRoute />
              </ErrorBoundary>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ErrorBoundaryContext>
  );
}

export default App;
