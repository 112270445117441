import Header from "./Header";
import Footer from "./Footer";

export default function Page() {
  return (
    <div className="h-screen p-5">
      <Header />
      <div className="flex h-5/6 overflow-y-auto flex-col justify-between items-center py-32 bg-white overflow-hidden">
        <div className="px-4 sm:px-6 flex items-center flex-1 lg:px-8">
          <div className="text-lg max-w-prose mx-auto mb-6">
            <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Privacy Policy
            </h1>
            <p className="text-xl text-center text-gray-500 leading-8">
              We currently don't collect any personal information. Please note
              that anonymised app usage data may be collected. Please write to
              aksanoble@gmail.com if you have any questions.
            </p>
          </div>
        </div>
      </div>
      <hr />
      <Footer />
    </div>
  );
}
