import Entity from "./Entity";
import { useState, useEffect } from "react";
import Grid from "./Grid";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const fetchResults = async (query) => {
  const response = await fetch(`${process.env.REACT_APP_SEARCH_URL}/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query }),
  });
  const data = await response.json();
  return data;
};

export default function Results(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const searchQuery = params.get("query");

  useEffect(() => {
    setIsLoading(true);
    const searchText = new URLSearchParams(window.location.search).get("query");
    const searchQuery = async (searchText) => {
      const data = await fetchResults(searchText);
      setResults(data);
    };
    searchQuery(searchText);
  }, [location]);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [results]);

  const Results = results.map((result, index) => (
    <Entity key={index} entity={result} />
  ));
  return (
    <div>
      <Header searchQuery={searchQuery} />
      {isLoading ? (
        <div className="md:w-2/4 self-center">
          <div>Loading...</div>
        </div>
      ) : (
        <div className="md:w-3/4 self-center">{Results}</div>
      )}
    </div>
  );
}
