import Logo from "../images/raven.svg";
import Footer from "./Footer";
import SearchBar from "./SearchBar";
export default function Home() {
  return (
    <div className="h-screen p-5">
      <div className="flex h-90 pt-52 justify-center">
        <div className="flex flex-col items-center w-5/6 md:min-w-xl md:w-3/6">
          <img className="block h-24 mb-1 w-auto" src={Logo} alt="Crow" />
          <SearchBar />
        </div>
      </div>
      <hr />
      <Footer />
    </div>
  );
}
